<template>
  <div id="app">
    <component v-bind:is="view"></component>
  </div>
</template>

<script>
import LaptopView from './views/LaptopView.vue'
import MobileView from './views/MobileView.vue'

export default {
  name: 'App',
  components: {
    'laptop-view': LaptopView,
    'mobile-view': MobileView,
  },
  data() {
    return {
      view: ''
    }
  },
  created() {
    if(window.innerWidth > 1200) {
      this.view = 'laptop-view'
    }else {
      this.view = 'mobile-view'
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      console.log('window', document.body.clientWidth);
      if(document.body.clientWidth > 1200) {
        this.view = 'laptop-view'
      }else {
        this.view = 'mobile-view'
      }
    }
  }
}
</script>

