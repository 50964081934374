<template>
  <div>
    <div id="main" class="">
      <div class="navbar cf">
        <div class="w-50 fl">
          <img class="logo" @click="imageSection=''" src="@/assets/images/logo.svg" alt="">
        </div>
        <div class="w-50 fr">
          <div class="menu Gotham">
            <p @click="imageSection=''">HOME</p>
            <p @click="gotoFooter">CONTACT</p>
          </div>
        </div>
      </div>

      <div class="cf relative">
        <transition name="slide-fade">
          <div v-if="!imageSection" class="fl w-50 leftBox relative">
            <div class="absolute bottom tc">
              <div class="tc w-65 mauto mv5">
                <lottie id="ani_home" :options="defaultOptions.ani_home"  v-on:animCreated="handleAnimation"/>
              </div>

              <p class="Gotham f2">REAL AUTHENTICATION</p>
              <p class="w-90 mauto tagline">Gain access to brand protection, consumer engagement and transparency provided by Trust Codes world leading cloud based solution.</p>
            </div>
          </div>
        </transition>
        
        <div class="w-50 menuContainer" :class="{'left': imageSection}">
          <div class=" rightBox">
            <div class=" bottom w-100">
              <ul class="Gotham w-100">
                <li class="line" :class="{'active': imageSection==='one'}" @click="imageSection = 'one'">WHAT WE DO</li>
                <li class="line" :class="{'active': imageSection==='two'}" @click="imageSection = 'two'">ABOUT US</li>
                <li class="line" :class="{'active': imageSection==='three'}" @click="imageSection = 'three'">ABOUT TRUST CODES®</li>
              </ul>
            </div>
          </div>
        </div>

        <transition name="slide-fade">
          <transition name="list" mode="out-in">
              <div v-if="imageSection==='one'" key="one" :class="{'active': imageSection==='one'}" class="fr w-50 relative changeSection">
                <div class="content">
                  <div class="flex items-center">
                    <div class="text w-50">
                      <p>
                        Provananz Global NZ Limited works with small to medium food and beverage producers to deploy Trust Codes in a secure, managed way- we work with your label or packaging providers to mark unique Trust Codes on your packaging; we help manage code deployment in a secure way that meets Trust Codes expectations, and we act as the liaison to deliver the world leading digitisation and brand protection that Trust Codes offers.
                      </p>
                      <p>
                        Our services mean that you can get on with making and processing amazing products, while we act as your brand protection experts.
                      </p>
                    </div>
                    <div class="w-50 relative aniContainer">
                      <lottie id="ani_page1" class="animation" :options="defaultOptions.ani_page1" v-on:animCreated="handleAnimation" />
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="imageSection==='two'" key="two" :class="{'active': imageSection==='two'}" class="fr w-50 relative changeSection">
                <div class="content">
                  <div class="flex items-center">
                    <div class="text w-50">
                      <p>
                        Provananz’s team has over 30 years’ experience servicing the local and international market as a technical product developer within the print and packaging industry. Provananz specialise in printing variable information applications from production and logistics through to consumer engagement.
                      </p>
                      <p>
                        Provananz has a market relationship with Trust Codes to provide product authentication, traceability and consumer engagement solutions.
                      </p>
                    </div>
                    <div class="w-50 relative aniContainer">
                      <lottie id="ani_page2" class="animation" :options="defaultOptions.ani_page2" v-on:animCreated="handleAnimation" />
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="imageSection==='three'" key="three" :class="{'active': imageSection==='three'}" @click="imageSection = 'three'" class="fr w-50 relative changeSection">
                <div class="content">
                  <div class="flex items-center">
                    <div class="text w-50">
                      <p>
                        Trust Codes is a data driven traceability engine and intelligently connect brands to their consumers, unlocking transparency and connectivity.
                      </p>
                      <p>
                        Trust Codes dynamically connects brands to their consumers through the use of a unique and intelligent digital identity for every individual item, going beyond batch level serialisation. Leveraging world leading algorithms and copy content tracking, they combat counterfeit while delivering meaningful, trusted engagement with consumers.
                      </p>
                      <p>
                        As products move through the supply chain, they capture data to provide insights promoting operational excellence allowing for full supply chain visualisation. The Trust Codes platform is built from the ground up for food, beverage and nutraceutical brands to deliver brand trust to consumers.
                      </p>
                      <a class="more" href="https://trust.codes" target="_blank">
                          Find out more
                          <img src="@/assets/images/arrow.svg" alt="">
                        </a>
                    </div>
                    <div class="w-50 tc relative aniContainer">
                      <div class="animation">
                        <lottie id="ani_page3" :options="defaultOptions.ani_page3" v-on:animCreated="handleAnimation" />
                        
                      </div>                     
                    </div>
                  </div>
                </div>
              </div>

            </transition>
            
        </transition>
      </div>
    </div>

    <div class="footer">
      <div class="w-50">
        <div class="title w-80">
          <p class="Gotham mt0">CONTACT US</p>
        </div>

        <div class="flex mv4">
          <div class=" person">
            <p>Julie Malone</p>
            <p>Director</p>
            <a href="mailto:julie@provananz.co.nz" target="_blank">julie@provananz.co.nz</a>
            <a href="tel:+64211560111" target="_blank">+64 21 1560 111</a>
          </div>
          <div class=" person">
            <p>Phill Jones</p>
            <p>Director</p>
            <a href="mailto:phill@provananz.co.nz" target="_blank">phill@provananz.co.nz</a>
            <a href="tel:+6421977739" target="_blank">+64 21 9777 39</a>
          </div>
        </div>

        <p>Address: Parnell Square, 12 Eglon Street Parnell. Auckland 1052, New Zealand.</p>
      </div>

      <div class="copyright tc">
        <p class="mb0">
          Copyright <span>{{getYear}}</span> Provananz Global NZ Limited. All rights reserved.
        </p>

        <p>Trust Codes<sup>®</sup> is a registered trademark of Trust Codes Limited in New Zealand.</p>
        
      </div>
    </div>
  </div>

</template>

<script>
import animateScrollTo from 'animated-scroll-to';
import Lottie from '@/components/lottie.vue';

import * as ani_home from '@/assets/animations/ani_home/data.json';
ani_home.assets.forEach((item, index) => {
  if(index < 7) {
    item.u = ''
    item.p = require(`@/assets/animations/ani_home/images/img_${index}.png`)
  }
});

import * as ani_page1 from '@/assets/animations/ani_page1/data.json';
ani_page1.assets.forEach((item, index) => {
  if(index < 7) {
    item.u = ''
    item.p = require(`@/assets/animations/ani_page1/images/img_${index}.png`)
  }
});

import * as ani_page2 from '@/assets/animations/ani_page2/data.json';
ani_page2.assets.forEach((item, index) => {
  if(index < 7) {
    item.u = ''
    item.p = require(`@/assets/animations/ani_page2/images/img_${index}.png`)
  }
});

import * as ani_page3 from '@/assets/animations/ani_page3/data.json';
ani_page3.assets.forEach((item, index) => {
  if(index < 7) {
    item.u = ''
    item.p = require(`@/assets/animations/ani_page3/images/img_${index}.png`)
  }
});


export default {
  name: 'LaptopView',
  data() {
    return {
      imageSection: '',
      defaultOptions: {
        ani_home: {
          animationData: ani_home.default,
          // path: '../public/animations/ani_home/data.json'
        },
        ani_page1: {
          animationData: ani_page1.default
        },
        ani_page2: {
          animationData: ani_page2.default
        },
        ani_page3: {
          animationData: ani_page3.default
        },
      }
    }
  },
  components: {
    Lottie
  },
  computed: {
    getYear() {
      return new Date().getFullYear()
    },
  },
  mounted() {
    // this.play('ani_home')
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    requirePath(path) {
      return require(path);
    },
    gotoFooter() {
      animateScrollTo(document.querySelector('.footer'));
    }
  }
}
</script>
<style lang="scss">
  .logo {
    cursor: pointer;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active,
  .menuContainer,
  .list-enter-active,
  .list-leave-active,
  li.line {
    transition: all 1s ease-in-out;
  }

  .slide-fade-enter {
    transform: translateX(100px);
    opacity: 0;
  }

  .slide-fade-leave-to {
    transform: translateX(-100px);
    opacity: 0;
  }

  .list-enter,
  .list-leave-to {
    opacity: 0;
    // transform: scale(0);
  }

  .w-65 {
    width: 65%;
  }
</style>