<template>
  <div class="relative">
    <div class="dropdown Gotham absolute pa3 pa4-m" :class="{'active': dropdown}">
      <p @click="menuHome" class="pb3 pb4-m">HOME</p>
      <p @click="changeTo('one')" class="pv3 pv4-m">WHAT WE DO</p>
      <p @click="changeTo('two')" class="pv3 pv4-m">ABOUT US</p>
      <p @click="changeTo('three')" class="pv3 pv4-m">ABOUT TRUST CODES<sup>®</sup></p>
      <p @click="menuContact" class="pv3 pv4-m">CONTACT</p>
    </div>
    <div class="pa3 pa4-m mb4 main" >
      <div class="menu cf ">
        <div class="fl w-80 tl">
          <img @click="imageSection=''" class="logo" src="@/assets/images/logo.svg" alt="">
        </div>
        <div class="fr w-20 tr">
          <img @click="dropdown=!dropdown" class="burger" src="@/assets/images/menu.svg" alt="">
        </div>
      </div>

      <div class="sections">
        <div class="tc w-70 mauto mb3 mt4">
          <lottie class="" id="ani_home" :options="defaultOptions.ani_home" v-on:animCreated="handleAnimation" />
        </div>
        <p class="Gotham f3 real tc mt4 ">REAL AUTHENTICATION</p>
        <p class="w-90 mauto tagline mb4 tc">Gain access to brand protection, consumer engagement and transparency provided by Trust Codes world leading cloud based solution.</p>

        <div class="titles">
          <ul class=" w-100">
            <li id="one" class="line Gotham" :class="{'active': imageSection==='one', 'hide': imageSection!=='one'}" @click="changeTo('one')">WHAT WE DO</li>

            <div class=" mainContent" :class="{'active': imageSection==='one'}">
              <div class="tc mt3 w-70 mauto" style="transform: translateX(-1rem)">
                <lottie id="ani_page1" :options="defaultOptions.ani_page1" v-on:animCreated="handleAnimation" />
              </div>
            
              <p>Provananz Global NZ Limited works with small to medium food and beverage producers to deploy Trust Codes in a secure, managed way- we work with your label or packaging providers to mark unique Trust Codes on your packaging; we help manage code deployment in a secure way that meets Trust Codes expectations, and we act as the liaison to deliver the world leading digitisation and brand protection that Trust Codes offers.</p>
              <p>
                Our services mean that you can get on with making and processing amazing products, while we act as your brand protection experts.
              </p>
            </div>

            <li id="two" class="line Gotham" :class="{'active': imageSection==='two', 'hide': imageSection!=='two'}" @click="changeTo('two')">ABOUT US</li>

            <div class=" mainContent " :class="{'active': imageSection==='two'}">
              <div class="tc w-70 mauto">
                <lottie id="ani_page2" :options="defaultOptions.ani_page2" v-on:animCreated="handleAnimation" />
              </div>
              <p>
                Provananz’s team has over 30 years’ experience servicing the local and international market as a technical product developer within the print and packaging industry. Provananz specialise in printing variable information applications from production and logistics through to consumer engagement.
              </p>
              <p>
                Provananz has a market relationship with Trust Codes to provide product authentication, traceability and consumer engagement solutions.
              </p>
            </div>

            <li id="three" class="line Gotham" :class="{'active': imageSection==='three', 'hide': imageSection!=='three'}" @click="changeTo('three')">ABOUT TRUST CODES<sup>®</sup></li>

            <div class=" mainContent " :class="{'active': imageSection==='three'}">
              <div class="tc w-70 mauto">
                <lottie id="ani_page3" :options="defaultOptions.ani_page3" v-on:animCreated="handleAnimation" />

                 
              </div>
              <p>
                Trust Codes is a data driven traceability engine and intelligently connect brands to their consumers, unlocking transparency and connectivity.
              </p>
              <p>
                Trust Codes dynamically connects brands to their consumers through the use of a unique and intelligent digital identity for every individual item, going beyond batch level serialisation. Leveraging world leading algorithms and copy content tracking, they combat counterfeit while delivering meaningful, trusted engagement with consumers.
              </p>
              <p>
                As products move through the supply chain, they capture data to provide insights promoting operational excellence allowing for full supply chain visualisation. The Trust Codes platform is built from the ground up for food, beverage and nutraceutical brands to deliver brand trust to consumers.
              </p>

              <a class="more mb4" href="https://trust.codes" target="_blank">
                  Find out more
                  <img src="@/assets/images/arrow.svg" alt="">
                </a>
            </div>

          </ul>
        </div>
      </div>

    </div>

    <div id="foot" class="foot pa3 pa4-m">
      <div class="title w-80">
        <p @click="scrollTo('foot')" class="Gotham mt5">CONTACT US</p>
      </div>

      <div>
        <div class="mv4 person">
          <p>Julie Malone</p>
          <p>Director</p>
          <a href="mailto:julie@provananz.co.nz" target="_blank">julie@provananz.co.nz</a>
          <a href="tel:+64211560111" target="_blank">+64 21 1560 111</a>
        </div>
        <div class="mv4 person">
          <p>Phill Jones</p>
          <p>Director</p>
          <a href="mailto:phill@provananz.co.nz" target="_blank">phill@provananz.co.nz</a>
          <a href="tel:+6421977739" target="_blank">+64 21 9777 39</a>
        </div>
      </div>

      <p>Address: Parnell Square, 12 Eglon Street Parnell. Auckland 1052, New Zealand.</p>

      <div class="copyright tc">
        <p class="mb2 mt4">
          Copyright <span>{{getYear}}</span> Provananz Global NZ Limited. All rights reserved.
        </p>
        <p class="mb4">Trust Codes<sup>®</sup> is a registered trademark of Trust Codes Limited in New Zealand.</p>
      </div>
    </div>
  </div>
</template>
<script>
import Lottie from '@/components/lottie.vue';
import animateScrollTo from 'animated-scroll-to';

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const targetElement = document.querySelector('body');

import * as ani_home from '@/assets/animations/ani_home/data.json';
ani_home.assets.forEach((item, index) => {
  if(index < 7) {
    item.u = ''
    item.p = require(`@/assets/animations/ani_home/images/img_${index}.png`)
  }
});

import * as ani_page1 from '@/assets/animations/ani_page1/data.json';
ani_page1.assets.forEach((item, index) => {
  if(index < 7) {
    item.u = ''
    item.p = require(`@/assets/animations/ani_page1/images/img_${index}.png`)
  }
});

import * as ani_page2 from '@/assets/animations/ani_page2/data.json';
ani_page2.assets.forEach((item, index) => {
  if(index < 7) {
    item.u = ''
    item.p = require(`@/assets/animations/ani_page2/images/img_${index}.png`)
  }
});

import * as ani_page3 from '@/assets/animations/ani_page3/data.json';
ani_page3.assets.forEach((item, index) => {
  if(index < 7) {
    item.u = ''
    item.p = require(`@/assets/animations/ani_page3/images/img_${index}.png`)
  }
});

export default {
  name: 'MobileView',
  components: {
    Lottie
  },
  data() {
    return {
      imageSection: '',
      dropdown: false,
      defaultOptions: {
        ani_home: {
          animationData: ani_home.default
        },
        ani_page1: {
          animationData: ani_page1.default
        },
        ani_page2: {
          animationData: ani_page2.default
        },
        ani_page3: {
          animationData: ani_page3.default
        },
      }
    }
  },
  computed: {
    getYear() {
      return new Date().getFullYear()
    },
    imageSectionHeight() {
      const winHeight = window.screen.availHeight;
      console.log('height', winHeight)
      return (winHeight - 550) / 2 + 'px';
    }
  },
  watch: {
    dropdown(value) {
      if(value) {
        disableBodyScroll(targetElement);
      }else {
        enableBodyScroll(targetElement);
      }
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    menuHome() {
      this.imageSection = '';
      this.dropdown = false;
    },
    changeTo(id) {
      this.dropdown = false;
      // this.imageSection = id;
      // window.scrollTo(400, 0);
      if(id === this.imageSection) {
        this.imageSection = ''
      }else {
        // animateScrollTo(document.getElementById(id));
        this.imageSection = id;
        setTimeout(() => {
          // this.imageSection = id;
          animateScrollTo(document.getElementById(id));
          console.log(id)
        }, 200);
      }
      
    },
    menuContact() {
      animateScrollTo(document.querySelector('.foot'));
      this.dropdown = false;
    },
    scrollTo(id) {
      animateScrollTo(document.getElementById(id));
    }
  }
}
</script>
<style lang="scss">
$gray89: #898989;
$gray_bg: #F7F7F7;
$blue: #5478B5;
$black: #000;

$size_title_laptop: 1.2rem;
$size_content_laptop: 1rem;
$size_small_laptop: 0.7rem;

  .logo {
    height: 1.5rem;
  }

  .main,
  .lists {
    min-height: 100vh;
  }

  .real {
    color: #5478B5;
  }

  .burger {
    height: 1rem;
    margin-top: 0.25rem;
  }

  .dropdown {
    background-color: $gray_bg;
    height: calc(100vh - 3.5rem);
    transform: scaleY(0);
    top: 3.5rem;
    width: 100%;
    position: absolute;
    z-index: 10;
    transition: all 0.8s ease-in-out;
    transform-origin: top;

    &.active {
      transform: scaleY(1);
    }

    p {
      border-bottom: 1px solid $gray89;
    }
  }

  img.bigPhoto {
    height: 25rem;
    margin-top: 2rem;
    margin: auto;
  }

  .titles {
    ul {
      margin: 0;
      list-style: none;
      padding: 0;

      li.line {
        color: $gray89;
        cursor: pointer;
        font-size: $size_title_laptop;
        padding: $size_title_laptop 0;
        margin-top: $size_title_laptop;
        border-bottom: 1px solid;
        border-color: $gray89;
        width: 100%;

        &.active {
          color: $blue;
          border-color: $blue;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .mainContent {
    line-height: 1.6;
    max-height: 0;
    overflow: hidden;

    &.active {
      max-height: 80rem;
      transition-delay: 0.6s;
      transition: all 1.2s ease-in-out;
    }
    
    &.hide {
      max-height: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  .imglist-enter-active,
  .imglist-leave-active {
    transition: all 0.7s ease-in-out;
  }

  .imglist-enter,
  .imglist-leave-to {
    opacity: 0;
  }

  .foot {
    background-color: $gray_bg;

    .title {
      font-size: $size_title_laptop;
      color: $gray89;
      border-bottom: 1px solid $gray89;
    }

    a {
      color: $black;
      display: block;
      // text-decoration: none;
      font-size: $size_content_laptop;
      margin-bottom: $size_content_laptop;
    }

    .copyright {
      font-size: $size_small_laptop;
    }
  }

  .tagline {
    color: $gray89;
  }

</style>